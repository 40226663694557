@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 360px;
  padding: grid(2.25) 0 0;
}

.header {
  flex-direction: row;
  align-items: start;
  z-index: 1;

  margin: 0 grid(3) 11px grid(2.5);
}

.topicTitle {
  flex-grow: 1;
  flex-shrink: 1;
}

.icon {
  margin-bottom: 12px;
}

.addons {
  display: flex;
  flex-direction: row;

  & > * {
    margin-right: grid();
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.toggleStageDone {
  margin: grid() grid(0.5);
}

.toggleStageDonePlaceholder {
  @extend .toggleStageDone;
  @include square(grid(3));
}

.activities {
  -webkit-app-region: no-drag;
  position: relative;
  flex: 1 2 auto;
  display: none;
  flex-direction: column;
  overflow: auto;
}

.loadingOuter {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include flex-center;
  flex: 1;
}

.note {
  display: none;
  width: 100%;
  overflow-y: scroll;
}

.poolDetails {
  flex: 1;
}

.activeTabPanel {
  display: flex;
}
