@import "uni/style/common";

.emoji {
  @include bold-text;
  font-size: 56px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.01;
  height: 74px;
}

.title {
  @include text-hero-web;
  text-align: center;
  margin: grid() 0 grid(2) 0;
}

.subtitle {
  @include text-headline-web-regular;
  text-align: center;

  @media screen and (max-width: 980px) {
    min-height: 64px;
  }

  @media screen and (max-width: 545px) {
    min-height: 96px;
  }
}
