@import 'uni/style/common';

.button {
  @include text-body-web-semibold;
  color: $blackColor;
  word-wrap: break-word;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: $gray2Color;
  border: none;
  box-shadow: none;
  border-radius: grid(1);

  min-height: grid(3.5);
  padding: grid(0.5) grid(1.5);
  margin: 0;

  user-select: none;

  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled) {
    background: $purple6Color;
  }

  &.__block {
    width: 100%;
    min-width: auto;
  }

  &.__primary {
    color: white;
    background: $purple5Color;
    @include shadowed($radius: 6px, $alpha: 0.1);

    &:hover {
      background: $purple6Color;
      @include shadowed($radius: 6px, $alpha: 0.14);
    }

    &:disabled {
      box-shadow: none;
    }
  }
}
