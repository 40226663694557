@import 'uni/style/common';

.formOuter {
  flex-basis: 528px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.title {
  @include text-headline-web-bold;

  margin-bottom: 40px;

  @include screen-s {
    margin-bottom: 32px;
  }
}

.adressOuter {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include screen-s {
    margin-top: 16px;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  flex: 1;
}

.formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  @include screen-s {
    flex-direction: column;
    gap: 0px;
  }
}

.cardElementOuter {
  min-height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    flex-grow: 1;
  }

  border: 1px solid $gray2Color;
  border-radius: 10px;
  padding: 8px 12px 7px;
  // TO KEEP
  flex: 1 1 auto;
}

.cardElementOuterFocused {
  border: 1px solid $purple4Color;
}

.stateAndZipCodeOuter {
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
