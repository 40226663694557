@import "uni/style/common";

.outer {
  margin-bottom: 20px;
}

.title {
  user-select: none;
  @include text-callout-web-regular;
  color: $gray6Color;
  margin-bottom: 6px;
}

.value {
  @include text-body-web-regular;
  color: $blackColor;
}
