@import "uni/style/common";

$transitionDuration: 200ms;

.outer {
  @include button-reset;
  height: 32px;
  width: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


  border-radius: 8px;
  transition: background $transitionDuration ease;
}

.icon {
  height: 24px;
  width: 24px;
  transition: color $transitionDuration ease;
}

.outer:hover {
  background-color: $gray1Color;
}

.outer.__selected {
  background-color: $purple1Color;
}

.outer .icon {
  color: $gray6Color;
}

.outer.__selected .icon {
  color: $purple5Color;
}
