@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  activityIndicatorColor: $darkPurpleColor;
  activityIndicatorSize: 60;
}
// stylelint-enable property-no-unknown

.outer {
  display: flex;
  flex: 1;
  flex-flow: row;
  height: 100%;
}

.workspaces {
  background: $gray1Color;
  width: 70px;
  flex-shrink: 0;
}

.primary {
  width: 216px;
  border-right: 1px solid $gray2Color;
  flex-shrink: 0;
}

.todos {
  border-right: 1px solid $gray2Color;
  flex: 1 1 480px;
  max-width: 664px;
}

.content {
  flex: 1 0 360px;
  border-right: 1px solid $gray2Color;
  display: flex;
  align-items: center;
  justify-content: center;
}
