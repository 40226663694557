@import 'uni/style/common';

.outer {
  position: absolute;
  display: flex;
  align-items: center;
  padding: grid(1);
  right: 80px;
  left: 80px;

  @include screen-s {
    right: 20px;
    left: 20px;
  }

  background: $flamingo1Color;
  border-radius: 8px;

  @include text-body-web-medium;
  font-size: 14px;

  svg,
  span {
    color: $flamingo5Color;
    margin-right: grid(1);
  }
}
