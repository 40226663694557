@import "uni/style/common";

.outer {
  display: inline-block;
  background-color: $gray1Color;
  margin: 1px 8px 0 0;
  border-radius: 5px;

  @include text-label-web-semibold;
  text-transform: uppercase;

  flex: 0 0;
  color: $gray5Color;
  white-space: nowrap;
  padding: 4px 6px 3px 6px;
}
