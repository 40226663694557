@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;

  opacity: 0.8;

  cursor: pointer;

  span {
    margin-left: 8px;
    @include text-body-web-semibold;
    color: $gray6Color;
  }

  color: $gray6Color;
}
