@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px 40px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > button {
    margin-top: 40px;
  }
}
