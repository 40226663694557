@import 'uni/style/common';

.icon {
  color: $gray6Color;
}

.uploadButton {
  transition: background-color 200ms ease;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  cursor: pointer;
}
