@import 'uni/style/common';

.section {
  margin-top: grid(6);
  display: flex;

  &Column {
    flex-flow: column;
  }

  &ContentEmpty {
    @include text-body-web-regular;
    /* Gray / Gray6 */
    color: #9095a2;
  }

  &Item {
    &Outer {
      margin-bottom: 20px;
    }
    &Border {
      border: 1px solid #ecedf0;
      border-radius: 10px;
    }
    &Title {
      user-select: none;
      @include text-callout-web-regular;
      color: #9095a2;
    }
    &Value {
      @include text-callout-web-regular;
      color: #161618;
    }
  }

  &ActionOuter {
    display: flex;
    flex-direction: row;
  }
}

.sectionError {
  display: flex;

  &Icon {
    margin-right: 8px;
    /* Dark Gray */
    color: #9393a3;
  }

  &Action {
    display: flex;
    margin-top: 20px;
  }
}

.sectionTitle {
  @include text-title-web-medium;
  font-size: 17px;
  color: #161618;
  margin-bottom: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

.button {
  @include button-secondary;

  &.__primary {
    @include button-primary;
  }

  margin-right: 8px;
}

.teamButton {
  width: 280px;
  align-items: center;
  justify-content: center;
  gap: 4px;

  margin-right: 0;

  // shrink the Loader to only what it needs
  & > div {
    flex: 0;
  }
}
