@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%; // Make sure almost the entire screen is clickable

  flex: 1 1 auto;
  overflow: hidden;

  height: 100%;
}


.inner {
  position: relative;

  display: flex;
  justify-content: center;

  flex: 1 1 auto;

  overflow: auto;

  max-width: 1152px;
  width: 100%;
}

.helpButtonOuter {
  position: absolute;
  left: 40px;
  bottom: 40px;
}
