@import 'uni/style/common';

@mixin medium-screen {
  @media (max-width: 959px) and (min-width: 768px) {
    @content;
  }
}

@mixin small-screen {
  @media (max-width: 767px) {
    @content;
  }
}

.listOuter {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $gray3Color;

  height: 100%;

  @include medium-screen {
    flex: 6;
  }

  .listInner {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: grid(3);
    height: 100%;
    .sectionTitle {
      @include text-body-web-semibold;
      color: $gray6Color;
      height: 40px;
      margin-top: 20px;
      padding: 10px 0 0 46px;
    }
  }
}
