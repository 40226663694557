@import 'uni/style/common';

.textarea {
  resize: vertical;
  min-height: 128px;
}

.input,
.textarea {
  @include text-body-web-regular;
  padding: grid(1) grid(1.5);
  width: 100%;
  background: $gray2Color;
  border: 2px solid $gray2Color;
  border-radius: 12px;
  color: $blackColor;
  resize: vertical;

  &:focus {
    border: 2px solid #9082f7;
    background: white;
  }

  &::placeholder {
    color: $gray6Color;
  }

  &.__error {
    border: 2px solid $flamingo6Color;
  }
}
