@import 'uni/style/common';

.outer {
  border-radius: grid(3);
  min-height: 144px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 352px;
  transition: all 0.1s linear;

  &:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  &.selectable {
    border-radius: 26px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.02);
    width: 320px;

    @media (max-width: 800px) {
      width: 100%;
    }

    &:hover {
      transform: none;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.02);
    }
  }

  @media (max-width: 1120px) {
    width: 100%;
  }

  @media (max-width: 760px) {
    min-width: 352px;
  }
}

.outer.__featured {
  height: 240px;
  width: 720px;
  @media (max-width: 1120px) {
    max-height: 144px;
    width: 100%;
  }
  @media (max-width: 760px) {
    min-width: 352px;
  }
}

.description {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 144px;
  margin: 20px 0 20px 20px;

  @media (max-width: 1120px) {
    max-width: 300px;
  }

  @media (max-width: 830px) {
    max-width: 144px;
  }
}

.outer.__featured .description {
  max-width: 300px;
  margin: 24px 0 24px 20px;
  @media (max-width: 830px) {
    max-width: 144px;
  }
}

.label {
  @include text-callout-web-medium;
  color: $whiteColor;
  opacity: 0.5;
  margin-bottom: 2px;
}

.outer.__featured .label {
  @include text-body-web-medium;
  color: $whiteColor;
  opacity: 0.5;
}

.title {
  @include text-body-web-semibold;
  color: $whiteColor;
}

.outer.__featured .title {
  @include text-title-web-bold;
  color: $whiteColor;
  @media (max-width: 1120px) {
    @include text-body-web-semibold;
    color: $whiteColor;
  }
}

.illustrationSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

.illustration {
  margin-right: 20px;
}

.outer.__featured .illustration {
  margin-right: 40px;
  @media (max-width: 1120px) {
    height: 144px !important;
    width: 144px !important;
    margin-right: 20px;
  }
}

.newBadgeOuter {
  background-color: $blackColor;
  border-radius: 28px;
  width: 42px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  margin: 12px 12px 0 0;
  position: absolute;
}
.newBadge {
  @include text-label-web-semibold;
  padding: 5px 9px;
  line-height: 15px;
  color: $whiteColor;
}
