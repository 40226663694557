@import "uni/style/common";

.outer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: hidden;
}

.head {
  min-height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  border-bottom: solid 1px $gray2Color;
}

.label {
  @include text-body-web-medium;
}

.body {
  flex: 1;
  overflow: auto;
}

.content {
  padding: 32px 32px 64px 32px;
  margin: auto;
  max-width: 600px;
  position: relative;
  box-sizing: content-box;
}

.title,
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  @include button-reset;
}

.icon {
  display: block;
  margin-right: 8px;
}
