@import 'uni/style/common';

.row {
  width: 100%;
  display: flex;
  gap: 8px;
}

.field {
  flex-basis: 50%;
  max-width: 200px;
}

.input {
  @include text-body-web-regular;
  padding: grid(1) grid(1.5);
  background: $gray2Color;
  border: 2px solid $gray2Color;
  border-radius: 12px;
  color: $blackColor;
  resize: vertical;
  width: 100%;

  &:focus {
    border: 2px solid #9082f7;
    background: white;
  }

  &::placeholder {
    color: $gray6Color;
  }

  &.__error {
    border: 2px solid $flamingo6Color;
  }
}

.title {
  @include text-body-web-regular;
  margin-bottom: 8px;
}
