$count: 150;
$maxWidth: 24;
$minDuration: 2;
$deltaDuration: 2;
$iterations: 1;
$delay: 0;
$colors: (#20F7FA, #7777E5, #FF3FD8);

// stylelint-disable property-no-unknown
:export {
  count: $count;
}
// stylelint-enable property-no-unknown

.outer {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
}

@for $i from 0 through $count {
  $w: random($maxWidth);
  $l: random(100);

  .particle-#{$i} {
    position: absolute;
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(length($colors)));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote($minDuration+random($deltaDuration)+"s") unquote(($delay + random())+"s") $iterations;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
}

