@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  flamingo6Color: $flamingo6Color;
}
// stylelint-enable property-no-unknown

.outer {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 10px grid(1);
  right: 0;
  left: 0;
  border-radius: 8px;
}

.noticeSuccess {
  background: $green1Color;

  &Content {
    @include text-body-web-medium;
    font-size: 14px;
    color: $green5Color;
  }

  &Icon {
    color: $green5Color;
    margin-right: grid(1);
  }
}

.noticeInfo {
  background: $gray1Color;

  &Content {
    @include text-body-web-medium;
    font-size: 14px;
    color: $gray6Color;
  }

  &Icon {
    color: $gray6Color;
    margin-right: grid(1);
  }
}

.noticeError {
  position: absolute;
  display: flex;
  align-items: center;
  padding: grid(1);
  right: 80px;
  left: 80px;
  top: 10px;

  background: $flamingo1Color;
  border-radius: 8px;

  &Content {
    @include text-body-web-medium;
    font-size: 14px;
    color: $flamingo6Color;
  }

  &Icon {
    color: $flamingo6Color;
    margin-right: grid(1);
  }
}
