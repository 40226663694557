@import 'uni/style/common';

.button {
  &Outer {
    display: flex;
    cursor: pointer;
    position: relative;
  }

  &Circle {
    width: 80px;
    height: 80px;
    background: $purple5Color;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08),
      0px 2px 16px rgba(0, 0, 0, 0.04);
    border-radius: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &VolumeBackground {
    z-index: 1;
    position: absolute;
    width: 80px;
    height: 80px;
    background: $purple1Color;
    border-radius: 70px;

    transition: scale 0.2s ease-in-out;
  }

  &Notice {
    @include text-callout-web-medium;
    position: absolute;
    bottom: -60px;
    width: 200px;
    left: -50%;
    color: $gray6Color;
  }

  &Icon {
    transform: scale(2.5);
    color: white;
  }
}

.cancel {
  &Outer {
    display: flex;
    cursor: pointer;
    position: absolute;
    bottom: 24px;
    right: 22px;
  }

  &Icon {
    color: $gray6Color;
  }
}

.outer {
  position: relative;
  border-top: 1px solid $gray2Color;
  height: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.__initializing {
    .buttonCircle {
      background-color: $gray5Color;
    }
  }
}
