@import 'uni/style/common';

$transitionDuration: 200ms;
$transitionShadow: ease;

.buttons {
  display: flex;
  @include flex-center;
  margin-top: grid(7);
}

.footnote {
  display: flex;
  @include flex-center;
  margin-top: grid(2);
}

.term {
  margin-top: grid(2);
  @include text-caption-web-regular;
  text-align: center;
  color: $grayColor;
}

.link {
  color: $purpleColor;
  text-decoration: none;
}

.outer {
  @include absolute;
  display: flex;
  flex-direction: column;

  box-sizing: content-box;
  max-width: 1104px;
  padding: 0 grid(3);
  margin: 0 auto;

  &.extraPadding {
    padding-top: 21px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
}
