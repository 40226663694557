@import 'uni/style/common';

.outer {
  margin-top: 48px;
}

.noticeError {
  position: sticky;
  right: 0 !important;
  left: 0 !important;
  top: -55px !important;
  width: 600px;
  margin: 0 auto;
  z-index: 10000;
}

.actionSection {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid $gray2Color;
}
