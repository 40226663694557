@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  right: 40px;
  bottom: 40px;

  button {
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: none;
    @include text-title-web-medium;
    padding: 10px;
    
    &.black {
      background: $blackColor;
      color: $whiteColor;
    }
    
    &.white {
      margin-top: 16px;
      color: $blackColor;
      background: $whiteColor;
    }
  }
}

