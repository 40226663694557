@import 'uni/style/common';

.outer {
  display: flex;
  padding: 0;
  margin: 0;
}

.card {
  flex: 1 1 33%;

  & + & {
    margin-left: 24px;
  }
}

.faded {
  color: $gray5Color;
}
