@import 'uni/style/common';

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.outer {
  border-radius: 16px;
  background: #161618;
  width: 296px;
  height: 100%;

  .snoozeDatePicker {
    padding: 8px;
  }

  .snoozeInfo {
    @include text-caption-web-regular;
    color: $gray6Color;
    border-top: 1px solid #2a2a2c;
    padding: grid(1.5) grid(2);
  }
}
