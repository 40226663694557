@import 'uni/style/common';

.reportingButtonSection {
  height: 62px;
  background: $gray1Color;
  border-right: solid 1px $gray2Color;
  margin-bottom: 10px;
}

.icon {
  color: $gray6Color;
  visibility: visible;
}

.reportingButtonSectionBadge {
  right: 5px;
  top: 4px;
  z-index: 1000;
}

.button.__selected {
  .icon {
    color: #fff;
  }
}

.tooltipText {
  @include text-callout-web-medium;
  color: $whiteColor;
}

.button {
  @include button-reset;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;

  position: relative;
}

.button.draggable {
  margin-top: 0px;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 44px;
  box-sizing: content-box;
  background: transparent;
  border-radius: 8px;
}

.button:hover .content {
  background: $gray2Color;
}

.button.__dragging .content {
  background: $whiteColor;
  cursor: grabbing;
}

.button.__selected .content,
.button.__selected:hover .content {
  background: $purple5Color;
  visibility: visible;
}

.iconContainer {
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.__selected .iconContainer {
  visibility: hidden;
}

.avatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  box-sizing: content-box;
  background: white;
}

.button.__disabled .avatar {
  filter: grayscale(1);

  img {
    opacity: 60%;
  }
}

.badge {
  position: absolute;
  top: 4px;
  right: 3px;
}
