@import "uni/style/common";

.outer {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include flex-center;
  flex: 1;
}

.text {
  @include text-body-web-regular;
  color: $gray4Color;
  user-select: none;
}