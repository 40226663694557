@import 'uni/style/common';

.outer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.contentOuter {
  overflow: auto;
  flex: 1 1 300px;
  width: 100%;

  display: flex;
  justify-content: center;
}

.contentInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerOuter {
  border-top: 1px solid $gray2Color;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: 104px;

  display: flex;
  justify-content: center;
  background-color: $whiteColor;

  @include screen-s {
    min-height: 0px;
  }
}

.footerInner {
  max-width: 928px;
  width: 100%;

  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  margin: 24px 20px 16px;

  @include screen-s {
    margin: 6px 12px;
    align-items: center;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
}

.nextLabel {
  text-align: center;
  min-width: 80px;
  margin: 0px;

  @include screen-s {
    min-width: 62px;
  }
}

.onSkipButton {
  padding: 12px 16px;
  margin-right: 16px;

  display: flex;
  align-items: center;

  cursor: pointer;

  @include text-body-web-regular;
  color: $gray6Color;

  text-decoration: underline;
}

.loaderOuter {
  margin: 4px 30px;
}
