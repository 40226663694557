@import "uni/style/common";

.outer {
  position: relative;
}

.inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
}

.text {
  flex: 1;
  padding: 8px 0 8px 8px;
}

.label {
  @include text-body-web-regular;
}

.description {
  @include text-body-web-regular;
  color: $gray6Color;
  margin-top: 6px;
}

.disabledOverlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(255,255,255,0.5);
}
