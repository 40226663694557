@import 'uni/style/common';

.skillWrapper {
  display: flex;
  flex-wrap: wrap;
}

.skill {
  flex: 0 0 50%;
  &:nth-child(2n) {
    padding-left: 8px;
  }
  &:nth-child(2n + 1) {
    padding-right: 8px;
  }
}
