@import "uni/style/common";

.outer {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  @include text-body-web-regular;
  user-select: none;
}

.message {
  @include text-body-web-regular;
  color: $gray2Color;
  user-select: none;
}
