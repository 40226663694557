@import 'uni/style/common';

$fadeDuration: 100ms;

.outer {
  position: relative;
  display: flex;
  min-width: 0px;
  flex: 1;
}

.overlay {
  background-color: $gray1Color;
  @include absolute;
  @include flex-center;
}

.overlayHidden {
  pointer-events: none;
  overflow: hidden;
  width: 0%;
  height: 0%;
  opacity: 0;
  transition: opacity $fadeDuration, width 0ms $fadeDuration, height 0ms $fadeDuration;
}
.overlayShow {
  pointer-events: auto;
  overflow: visible;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity $fadeDuration;
  z-index: 100;
}

.text {
  @include text-body-web-regular;
  color: $gray6Color;
  font-size: 17px;
  user-select: none;
}
