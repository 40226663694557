@import 'uni/style/common';

.outer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 1px 0px $gray2Color;
  padding: grid(9) 40px;
  overflow-y: scroll;
}

.inner {
  background-color: lavender;
}

.step {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  & + & {
    margin-top: grid(8);
  }
}

.textStack {
  display: flex;
  flex-direction: column;
  margin-left: grid(4);
}

.title {
  @include text-body-web-medium;
}

.subtitle {
  @include text-body-web-regular;
  color: $gray6Color;
  margin-top: 4px;
}
