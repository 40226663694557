@import 'uni/style/common';

.outer {
  flex: 1;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
}

.checkedIcon {
  color: $purple5Color;
}

.uncheckedIcon {
  color: $gray5Color;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  height: 311px;
  width: 600px;
  z-index: 10;
}

.text {
  @include text-headline-web-regular;
  text-align: center;
  color: $gray6Color;
  margin: 64px 60px 43px 60px;
}

.checkboxSection {
  @include text-caption-web-regular;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 27px;
  user-select: none;
}

.checkboxSection span {
  margin-left: 8px;
  color: $gray6Color;
}

.privacyCheckBox {
  height: 24px;
  width: 24px;
}

.termsPrivacyLink {
  color: $purple5Color;
  text-decoration: none;
}

.button {
  @include button-reset;
  user-select: none;
  cursor: pointer;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;

  background: $purple5Color;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.08);
  border-radius: 32px;

  width: 163px;
  height: 55px;
  padding: 0 12px;

  &[disabled] {
    opacity: 0.5;
    cursor: unset;
  }
}

.buttonLabel {
  @include text-body-web-semibold;
  color: #fff;
  text-align: center;
  flex: 1.7;
}
