@import 'uni/style/common';
// Attachment Upload Indicator

@keyframes spinner {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg); }
}

.uploading {
  animation: spinner 1s infinite linear;
  color: $gray6Color;
  margin: grid(1);
}

.uploaded {
  color: mix($greenColor, black, 90%);
  margin: grid(1);
}

.failed {
  color: mix($flamingoColor, black, 90%);
  margin: grid(1);
}

.attachmentUploadIndicatorIcon {
  margin-right: grid(1);
}

.attachmentUploadIndicatorContainer {
  @include absolute;
  width: 100%;
  z-index: 3;
  pointer-events: none;
}

.attachmentUploadIndicatorContent {
  margin: grid(1.5) grid(2.25);
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.attachmentUploadIndicatorUploading {
  background-color: mix($gray6Color, white, 10%);
  border-radius: grid(1);
}
.attachmentUploadIndicatorUploadingText {
  @include text-body-web-medium;
  font-size: 14px;
  color: $gray6Color;
  margin: 0;
}

.attachmentUploadIndicatorUploaded {
  background-color: mix($greenColor, white, 10%);
  border-radius: grid(1);
}
.attachmentUploadIndicatorUploadedText {
  @include text-body-web-medium;
  font-size: 14px;
  color: mix($greenColor, black, 90%);
  margin: 0;
}

.attachmentUploadIndicatorFailed {
  background-color: mix($flamingoColor, white, 10%);
  border-radius: grid(1);
}
.attachmentUploadIndicatorFailedText {
  @include text-body-web-medium;
  font-size: 14px;
  color: mix($flamingoColor, black, 90%);
  margin: 0;
}
