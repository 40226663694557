@import 'uni/style/common';

.outer {
  display: flex;
  flex-grow: 1;
  box-shadow: 0px 1px 0px $gray2Color;
  padding: grid(7) grid(6);
  overflow-y: scroll;

  min-height: 488px;
}

.inner {
  display: flex;
  flex-direction: column;
}

.name {
  margin-left: grid(2);
}

.nameContainer {
  display: flex;
  flex-direction: row;
  margin-left: grid(-2);
}

.email {
  margin-top: grid(4);
}

.taskSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: grid(4);
  padding: grid(1) 0;

  .label {
    padding-left: grid(1);
    @include text-body-web-regular;
    cursor: pointer;
  }
}

.hours {
  display: flex;
  flex-direction: column;
  margin-top: grid(4);

  .header {
    display: flex;
    justify-content: space-between;
    @include text-callout-web-regular;

    .prompt {
      color: $gray6Color;
      flex-shrink: 1;
    }
  }

  .footer {
    @include text-caption-web-regular;
    color: $gray6Color;
  }
}

.pricingInfo {
  margin-top: grid(4);
}
