@import 'uni/style/common';

.learnMore {
  text-decoration: none;
  color: $purple5Color;
  cursor: pointer;
}

.modalOuter {
  min-height: 540px;
  max-width: 512px;
  position: relative;
  padding: 32px 24px 0 24px;
}

.toolTipTitle {
  @include text-headline-web-bold;
  margin-bottom: 16px;
}

.toolTipSubtitle {
  @include text-body-web-regular;
  margin-top: 8px;
  margin-bottom: 8px;
}

.modalCloseBtn {
  @include button-default-primary;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
