@import 'uni/style/common';

.outer {
  padding: 28px 32px 32px;

  @include screen-s {
    padding: 20px;
  }

  border-radius: 24px;

  max-width: 672px;

  background-color: white;

  p,
  li,
  span {
    @include text-title-web-regular;
    color: $darkGrayColor;
  }

  & p,
  div {
    margin-top: 0px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  & ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style-type: none;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.shadow {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 12px 24px rgba(0, 0, 0, 0.04);
}

.border {
  border: 1px solid $gray3Color;
}

.title {
  @include text-headline-web-bold;
  color: black;
}
