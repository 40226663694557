@mixin button-reset () {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  cursor: pointer;
  text-align: left;
}

@mixin input-reset () {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  text-align: left;
}
