@import "uni/style/common";

.outer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;

  transform: translateX(-50%);
  left: 50%;

  &.__top {
    top: 16px;
  }

  &.__bottom {
    bottom: 16px;
  }
}
