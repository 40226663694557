@import 'uni/style/common';

.outer {
  position: relative;
}

.body {
  margin-top: 24px;
  margin-bottom: 48px;
}

.loaderOverlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
}

.error {
  display: block;
  @include text-callout-web-semibold;
  margin-bottom: grid(1.5);
  color: $flamingo6Color;
}
