@import "uni/style/common";

.outer {
  margin-bottom: grid(3);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: grid(2);
  margin-bottom: grid(3);

  img {
    width: 24px;
  }
}

.heading {
  @include text-body-web-semibold;
  margin-left: grid(1.5);
}

.calendars {
  border-radius: grid(2);
  padding: grid(4) grid(3);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 2px 16px rgba(0, 0, 0, 0.04);
}

.calendar {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
}

.calendar + .calendar {
  margin-top: grid(2);
}

.calendarTitleOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.calendarTitle {
  @include text-body-web-regular;

  .primary {
    color: $gray6Color;
  }
}

.calendarBullet {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0 19px 0 7px;
}

.calendarSelectOuter {
  position: relative;
}

.calendarSelectIcon {
  color: $gray6Color;
  position: absolute;
  right: 7px;
  top: 8px;
  pointer-events: none;
}

.calendarSelect {
  @include text-body-web-regular;

  background: #ECEDF0;
  border-radius: 10px;
  padding: 8px 12px 7px 12px;
  border: 2px solid #ECEDF0;
  width: 160px;

  appearance: none;

  &::placeholder {
    color: #9095A2;
  }

  &:focus {
    border: 2px solid #9082F7;
    background: white;
  }
}
