@import "uni/style/common";

.outer {
  flex: 1;
  min-width: 1024px;
  width: calc(100% - 96px);
  overflow: hidden;
  margin: 0 auto;
  padding: 0 48px 40px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 24px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.link {
  text-decoration: none;
}

.label {
  @include text-body-web-medium;

  margin-left: 8px;
}

.btnOuter:hover .label {
  color: $purple5Color;
}

.btnOuter:hover .icon {
  color: $purple5Color;
}

.links {
  margin: 24px 0 0 0;
  padding: 0;
  list-style: none;

  li, a {
    @include text-callout-web-medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: $gray6Color;
  }

  li + li {
    margin-top: 16px;
  }

  a {
    text-decoration: none;
    color: $purple5Color;
  }
}

.noReportsPlaceholder {

  &Outer {
    border: 1px solid #ECEDF0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 105px 192px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &Icon {
    color: $gray6Color;
    margin-bottom: 16px;
  }

  &Title {
    @include text-title-web-medium;
    color: $gray6Color;
    margin-bottom: 4px;
  }

  &Subtitle {
    @include text-body-web-regular;
    color: $gray6Color;
  }
}
