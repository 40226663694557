@import "uni/style/common";

.outer {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  color: $gray5Color;
  margin-bottom: 10px
}

.message {
  @include text-title-web-medium;
  color: $gray5Color;
  user-select: none;
}
