@import "uni/style/common";

.item {
  display: block;
  margin-bottom: 2px;
}

.item .link {
  @include button-reset;

  display: flex;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: $gray6Color;

  .icon {
    margin: 0 8px 0 6px;
  }

  .icon,
  .label {
    @include text-body-web-medium;
    color: $gray6Color;
  }
}

.item.__selected .link {
  background: $gray2Color;
  color: $blackColor;

  .icon,
  .label {
    color: $blackColor;
  }
}

.item:hover .link {
  background: $gray2Color;
  color: $gray6Color;

  .icon,
  .label {
    color: $gray6Color;
  }
}

.item.__danger .link,
.item:hover.__danger .link {
  color: $flamingo6Color;

  .icon,
  .label {
    color: $flamingo6Color;
  }
}

.item:hover.__selected .link {
  color: $blackColor;

  .icon,
  .label {
    color: $blackColor;
  }
}

