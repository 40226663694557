@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  defaultBgColor: $gray2Color;
}
// stylelint-enable property-no-unknown

.titleOuter {
  padding: grid(3);
  user-select: none;
}

.title {
  @include text-heading-web-bold;
  color: $blackColor;
  margin-bottom: grid(1.5);
}

.description {
  @include text-body-web-regular;
  color: $blackColor;
}

.illustrationOuter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 320px;
}
