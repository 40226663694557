@import 'uni/style/common';

.infoText {
  @include text-callout-web-medium;
  color: $gray6Color;
  margin: 0 grid();
  text-align: center;
}

.infoTextBold {
  font-weight: bold;
}

.infoTextNoMargin {
  margin: 0;
}
