@import "uni/style/common";

.container {
  width: 100%;
  justify-content: space-around;
  overflow: hidden;
  display: flex;
  flex-flow: row;
}

.option {
  @include rounded();
  display: flex;
}

.rounded {
  @include square(grid(4));
  align-items: center;
  justify-content: center;
  background: rgba($grayColor, 0.14);
}

.selectedOption {
  background: $lightPurpleColor;
}

.optionText {
  @include text-body-web-regular;
  text-align: center;
  padding: grid(1) grid(2);
  color: $gray6Color;
}

.selectedOptionText {
  @include text-body-web-semibold;
  color: $darkPurpleColor;
}
