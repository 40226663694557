@import "uni/style/common";

.outer {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: grid(10);
}

.copyright {
  @include text-callout-web-medium;
  color: $gray6Color;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;

  margin: 0;
  list-style: none;

  padding: 0 grid(2);

  & > li {
    padding: 0 grid(2);
  }
}

.link {
  @include text-callout-web-medium;
  text-decoration: none;
  transition: color .2s ease-in-out;

  &:hover {
    color: $gray6Color;
  }
}
