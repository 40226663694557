@import 'uni/style/common';

.outer {
  overflow: auto;
  height: 100%;
  width: 100%;

  padding-bottom: 64px;

  @include screen-s {
    padding: 12px;
  }
}

.checkedIcon {
  color: $purple5Color;
}

.checkIcon {
  color: $gray6Color;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include screen-s {
    align-items: stretch;
  }
}

.content {
  max-width: 928px;
  margin: 80px 80px 33px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @include screen-s {
    flex-direction: column;
    align-items: stretch;
    margin: 0px;
  }
}

.avatar {
  border-radius: 1000px;
  background-color: $gray5Color;
  filter: drop-shadow(0px 16px 80px $gray2Color);

  width: 240px;
  height: 240px;

  @include screen-s {
    width: 120px;
    height: 120px;
  }
}

.infos {
  margin-left: 80px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include screen-s {
    margin-left: 0px;
    align-items: stretch;
  }
}

.title {
  @include text-hero-web;

  @include screen-s {
    @include text-headline-web-bold;
    margin-top: 32px;
  }
}

.subtitle {
  margin-top: 12px;

  @include text-headline-web-regular;
  color: $gray6Color;

  @include screen-s {
    margin-top: 8px;
    @include text-title-web-regular;
    color: $gray6Color;
  }
}

.bio {
  margin-top: 40px;
  @include text-title-web-regular;
  color: $gray6Color;

  @include screen-s {
    margin-top: 32px;
    @include text-body-web-regular;
    color: $gray6Color;
  }
}

.highlights {
  margin-top: 40px;
  padding: 20px 24px 24px;

  background-color: $gray1Color;
  border-radius: 12px;

  li,
  span {
    @include text-title-web-regular;
    color: $gray6Color;
  }

  & ul {
    margin: 24px 0px 0px;
    padding: 0px;

    li {
      list-style-type: none;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.highlightsTitle {
  @include text-title-web-medium;
  color: black;
}

.privacyOuter {
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  margin: 24px 0px;
  @include screen-s {
    margin: 32px 0px;
  }

  & span {
    margin-left: 8px;
  }

  & span,
  a {
    @include text-callout-web-regular;
    color: $gray6Color;
  }
}

.bottomButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > span {
    margin: 16px;
    @include text-body-web-regular;
    color: $gray6Color;
  }

  @include screen-s {
    flex-direction: column;
  }
}
