@import 'uni/style/common';

.outer {
  height: calc(100vh - 200px);
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  text-align: center;
}
