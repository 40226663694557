@import "./colors";
@import "./devices";
@import "./layout";
@import "./text";
@import "./buttons";
@import "./deprecated_text_mobile_only";
@import "./reset";
@import "./functions";

@mixin shadowed($offsetX: 0, $offsetY: 2px, $radius: grid(0.5), $color: null, $alpha: 0.14) {
  box-shadow: $offsetX $offsetY $radius if($color != null, $color, rgba(1, 1, 1, $alpha));
}

@mixin placeholder () {
  &::-ms-input-placeholder {
    @content
  }

  &::-webkit-input-placeholder,
  &::placeholder {
    @content
  }
}

@mixin placeholder-color ($color) {
  @include placeholder {
    color: $color;
  }
}

@mixin hideScrollBar {
  // Safari and Chrome
  &::-webkit-scrollbar {
    display: none;
  }

  // FF
  overflow: -moz-scrollbars-none;

  // IE 10 and edge
  -ms-overflow-style: none;
}
