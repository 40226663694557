@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & svg {
    flex-shrink: 0;
  }
}

.icon {
  margin-right: 8px;
}

