@import "uni/style/common";

// stylelint-disable property-no-unknown
:export {
  grayColor: $gray6Color;
}
// stylelint-enable property-no-unknown


.iconOuter {
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
