@import 'uni/style/common';

.outer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 0px 1px 0px $gray2Color;
  overflow-y: scroll;
  min-height: 488px;
}
