@import 'uni/style/common';

.outer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // Add a padding the size of the steps indicator so it's centered vertically
  padding-bottom: 112px;
}

.inner {
  max-width: 500px;
  padding: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include text-hero-web;
  margin-bottom: 16px;
  color: black;
  text-align: center;

  @include screen-s {
    @include text-heading-web-bold;
    color: black;
  }
}

.subtitle {
  @include text-headline-web-regular;
  margin-bottom: 40px;
  text-align: center;
  color: $gray6Color;

  @include screen-s {
    @include text-body-web-regular;
    color: $gray6Color;
  }
}
