@import 'uni/style/common';

.outer {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  border-radius: 58px;
  width: calc(100% - 32px);
  padding: 9px 12px;
  display: flex;
  margin: 0 16px 16px 16px;
  top: -50px;
  align-items: center;
  pointer-events: none;
  z-index: 3;
}

.icon {
  color: $gray6Color;
  margin-right: 8px;
}

.text {
  @include text-caption-web-regular;
  color: #000;
}
