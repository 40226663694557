@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}

.inner {
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
}

.stepIndicatorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @include screen-s {
    padding: 0px 56px;
  }
}
