@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  margin: grid(2) 0 grid(3) 0;

  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: unset;
  }
}

.wrapper {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}
