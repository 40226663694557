@import 'uni/style/common';

.outer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.imageWidgetVisible {
  width: 100%;
  height: 100%;
  visibility: visible;
}

.imageWidgetHidden {
  visibility: hidden;
}

.retryIcon {
  color: $gray6Color;
  margin-right: grid();
}

.imageLoader {
  height: 100%;
  padding: grid(1.5);
  position: absolute;
}

.retryImageLoad {
  height: 100%;
  padding: grid(1.5);
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  cursor: pointer;
  user-select: none;

  @include text-callout-web-medium;
  color: $gray6Color;
}
