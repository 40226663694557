@import 'uni/style/common';

.inner {
  padding: 1px 5px;
}

.outer {
  @include text-label-web-bold;
  text-align: center;

  background-color: $gray2Color;
  min-width: 17px;
  min-height: 17px;
  border-radius: 20px;
}
