@import 'uni/style/common';

.inner {
  padding: 0 0 24px;
}

.tableOuter {
  padding: 7px 0px 16px;
  overflow-y: auto;
}

.info {
  @include text-label-web-regular;
  color: $gray6Color;
  padding: 0 24px;
}

.loader {
  margin: 24px;
}
