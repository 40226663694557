@import "uni/style/common";

.content {
  width: 600px;
  margin: 73px auto;
}

.headline {
  @include text-headline-web-bold;
  color: $blackColor;
  margin-bottom: grid(6);
}

.hero {
  .avatarOuter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .avatarWrapper {
      overflow: hidden;
      @include circle(80px);
      margin-bottom: 8px;
      border: 1px solid $gray2Color;
      margin-right: 16px;
    }
  
    .avatarImage {
      width: 80px;
      height: 80px;
    }

    .downloadButton {
      cursor: pointer;
      color: $gray5Color;
      &:hover {
        color: $purple5Color;
      }
    }
  }

  .labelContainer {
    display: flex;
    flex-direction: column;
    padding: 9px 0 9px 0;

    .label {
      @include text-body-web-medium;
    }

    .subLabel {
      @include text-body-web-regular;
      color: $gray6Color;
      margin-top: 4px;
    }
  }
}
