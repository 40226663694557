@import './devices';

$gridBase: 8px;

@function grid($multiplier: 1) {
  @return $gridBase * $multiplier;
}

@mixin rounded($radius: null) {
  border-radius: if($radius != null, $radius, 9999px);
}

@mixin shape($width, $height, $radius: null) {
  width: $width;
  height: $height;
  @if $radius != null {
    @include rounded($radius);
  }
}

@mixin square($size, $radius: null) {
  @include shape($size, $size, $radius);
}

@mixin circle($size) {
  @include square($size, if($size, calc($size / 2), 9999px));
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin absoluteUneven($verticalSpacing: 0, $horizontalSpacing: 0) {
  @include absolute(
    $verticalSpacing,
    $horizontalSpacing,
    $verticalSpacing,
    $horizontalSpacing
  );
}

@mixin absoluteEven($spacing: 0) {
  @include absoluteUneven($spacing, $spacing);
}

@mixin padding-vertical($padding) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin padding-horizontal($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin margin-vertical($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin margin-horizontal($margin) {
  margin-left: $margin;
  margin-right: $margin;
}

@mixin flex-center {
  align-items: center;
  justify-content: center;
}

@mixin hideScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
}
