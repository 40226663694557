@import 'uni/style/common';

.loadingOuter {
  margin-top: grid(2);
}

.errorOuter {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorTitle {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: grid(1);
}
