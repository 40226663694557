@import "uni/style/common";

.outer {
  @include button-reset;
  position: relative;

  display: flex;
  flex-direction: column;
  width: auto;

  border-radius: 8px;

  padding: 7px 10px;
  margin: 0 10px;

  justify-content: center;

  .inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .unread {
      position: absolute;
      right: 12px;
    }

    .external {
      position: absolute;
      right: 8px;
    }

    .label {
      display: inline;
      @include text-body-web-medium;
      padding-left: 10px;
    }

    .label,
    .external,
    .icon,
    svg {
      color: $gray6Color;
    }
  }

  &:hover {
    background: mix($grayColor, white, 16%);

    .external,
    .icon,
    .label,
    svg {
      color: $gray6Color;
    }
  }

  &:active {
    background: $gray1Color;
  }

  &.__selected {
    background: $gray2Color;

    .external,
    .label,
    .icon,
    svg {
      color: $blackColor;
    }

    &:active {
      background: mix($darkPurpleColor, white, 14%);
    }
  }
}

.badge {
  position: absolute;
  right: 8px;
}