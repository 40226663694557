@import 'colors';

@mixin desktop-font-family-for-size($size) {
  font-family: #{if($size >= 20px, 'SF Pro Display', 'SF Pro Text')}, system,
    -apple-system, BlinkMacSystemFont, '.SFNSDisplay-Regular', 'Segoe UI',
    SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin font-size-with-spacing($size, $spacing: 0) {
  font-size: $size;
  letter-spacing: #{$size * $spacing};
}

@mixin desktop-font-size-with-spacing($size, $spacing: 0) {
  @include desktop-font-family-for-size($size);
  @include font-size-with-spacing($size, $spacing);
}

@mixin mobile-font-size-with-spacing($size, $spacing: 0) {
  @include font-size-with-spacing($size, $spacing);
}

// HELPERS

@mixin base-text {
  color: $blackColor;
  font-weight: normal;
}

@mixin medium-text {
  font-weight: 500;
}

@mixin semibold-text {
  font-weight: 600;
}

@mixin bold-text {
  font-weight: 700;
}

@mixin heavy-text {
  font-weight: 800;
}

@mixin base-web-text {
  @include base-text;
  // react-native don't like font-smoothing
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

// HERO

@mixin text-hero-web {
  @include base-web-text;
  @include bold-text;

  font-size: 48px;
  line-height: 110%;
  letter-spacing: -1px;
}

// HEADING

@mixin text-heading-web-bold {
  @include base-web-text;
  @include bold-text;

  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0px;
}

// HEADLINE

@mixin text-headline-web-regular {
  @include base-web-text;
  font-size: 24px;
  line-height: 135%;
}

@mixin text-headline-web-bold {
  @include text-headline-web-regular;
  @include bold-text;
}

// TITLE

@mixin text-title-web-regular {
  @include base-web-text;
  font-size: 18px;
  line-height: 140%;
}

@mixin text-title-web-medium {
  @include text-title-web-regular;
  @include medium-text;
}

@mixin text-title-web-bold {
  @include text-title-web-regular;
  @include bold-text;
}

// BODY

@mixin text-body-web-regular {
  @include base-web-text;
  font-size: 15px;
  line-height: 140%;

  @include screen-s {
    font-size: 16px;
  }
}

@mixin text-body-web-medium {
  @include text-body-web-regular;
  @include medium-text;
}

@mixin text-body-web-semibold {
  @include text-body-web-regular;
  @include semibold-text;
}

@mixin text-body-web-bold {
  @include text-body-web-regular;
  @include bold-text;
}

// CALLOUT

@mixin text-callout-web-regular {
  @include base-web-text;
  font-size: 14px;
  line-height: 140%;
}

@mixin text-callout-web-medium {
  @include text-callout-web-regular;
  @include medium-text;
}

@mixin text-callout-web-semibold {
  @include text-callout-web-regular;
  @include semibold-text;
}

// LABEL

@mixin text-label-web-regular {
  @include base-web-text;
  font-size: 11px;
  line-height: 140%;
}

@mixin text-label-web-semibold {
  @include text-label-web-regular;
  @include semibold-text;
}

@mixin text-label-web-bold {
  @include text-label-web-regular;
  @include bold-text;
}

// CAPTION

@mixin text-caption-web-regular {
  @include base-web-text;
  font-size: 13px;
  line-height: 140%;
}

@mixin text-caption-web-semibold {
  @include text-caption-web-regular;
  @include semibold-text;
}
