@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.titleImg {
  color: $gray6Color;
  margin-right: 8px;
}

.inner {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}
