@import 'uni/style/common';

.outer {
  display: flex;

  margin-top: 40px;
  margin-bottom: 56px;

  @include screen-s {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}

$itemWidth: 64px;

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $itemWidth;
}

.iconAndSegmentOuter {
  position: relative;
  display: flex;
}

$iconSize: 24px;
$segmentWidth: 3px;

@mixin segmentBase {
  background-color: $gray3Color;
  height: 3px;

  &.highlighted {
    background-color: $purple5Color;
  }
}

.segmentLeft {
  position: absolute;
  right: calc($iconSize / 2);
  top: calc(($iconSize - $segmentWidth) / 2);
  width: calc($itemWidth / 2) + 1;

  @include segmentBase;
}

.segmentRight {
  position: absolute;
  left: calc($iconSize / 2);
  top: calc(($iconSize - $segmentWidth) / 2);
  width: calc($itemWidth / 2) + 1;

  @include segmentBase;
}

.stretchingSegment {
  margin-top: calc(($iconSize - $segmentWidth) / 2);
  flex-grow: 1;

  @include segmentBase;
}

.checkIcon {
  color: $purple5Color;
  z-index: 1;
}

.circleIcon {
  color: $gray3Color;
  fill: white;
  z-index: 1;

  &.highlighted {
    color: $purple5Color;
  }
}

.circleSmallFilledIcon {
  fill: currentColor !important;
}

.label {
  @include text-caption-web-semibold;
  color: $gray5Color;
  margin-top: 12px;
  text-align: center;

  &.highlighted {
    color: $purple5Color;
  }

  @include screen-s {
    display: none;
  }

  &.selected {
    display: block;
  }
}
