@import "uni/style/common";

.tabs {
  display: flex;
  justify-content: center;

  border: 2px solid $gray2Color;
  border-radius: 10px;
  margin-bottom: grid(3);

  user-select: none;
}

.tab {
  @include text-body-web-regular;
  color: $gray6Color;
  cursor: pointer;
  padding: 8px 0;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  &.__selected {
    background: $gray1Color;
    color: $blackColor;

    &:first-child {
      border-radius: 10px 0px 0px 10px;
    }

    &:last-child {
      border-radius: 0px 10px 10px 0px;
    }
  }
}

.addressPreview {
  border: 2px solid $gray2Color;
  border-radius: 12px;
  padding: grid(2) grid(3);

  display: flex;
  flex-direction: column;
}

.addressPreviewLine {
  @include text-title-web-medium;
  color: $blackColor;
}

.addressForm {
  display: flex;
  flex-flow: column;
}
