@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;
  overflow: auto;
}

.title {
  @include text-headline-web-bold;
  color: $blackColor;
  margin-top: 0px;
  text-align: center;
}

.subtitle {
  @include text-title-web-regular;
  color: $gray6Color;
  margin: 8px 0px;
  text-align: center;
}
