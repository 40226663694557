@import "uni/style/common";

.table {
  table-layout: fixed;
  border-collapse: collapse;

  td.corner {
    border: none;
  }

  td {
    @include text-callout-web-regular;

    border: 1px $gray2Color solid;
    padding: 6px 12px;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;

    a {
      text-decoration: none;
    }
  }

  td.rowLabel {
    text-align: left;
    width: 183px;
  }

  tr.lightEmphasis {
    background-color: $gray1Color;
  }

  tfoot {
    background-color: $gray2Color;
  }
}

.table + .table {
  margin-left: 14px;
}
