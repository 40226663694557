@import 'uni/style/common';

.outer {
  display: flex;
  user-select: none;
}

.leftActions {
  flex: 1;
}

.leftActions, .rightActions {
  display: flex;
  align-items: center;
  flex-direction: row;
}
