@import 'uni/style/common';

.clickToOpen {
  @include text-callout-web-medium;
  color: $purpleColor;
  display: none;
  margin-left: 5px;
}

.outer {
  display: flex;
  align-items: center;
  flex-flow: row;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    .clickToOpen {
      display: block;
    }
  }
}

.left {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-shrink: 0;
}

.icon {
  color: $gray6Color;
}

.right {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-shrink: 0;
}

.filename {
  @include text-callout-web-medium;
  color: black;
  margin-bottom: 2px;
}

.subtitle {
  display: flex;
  flex-flow: row;
}

.filesize {
  @include text-callout-web-medium;
  color: $gray6Color;
}
