@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 16px;
  padding: 0 16px;

  img {
    width: 46px;
    height: 48px;

    margin-bottom: 24px;
  }
}

.title {
  @include text-title-web-bold;
  color: $gray6Color;
  margin-bottom: 24px;
}

.description {
  p {
    @include text-body-web-regular;
    color: $gray6Color;
    margin: 0px;

    &:last-child {
      margin-top: 20px;
    }
  }
}
