@import "uni/style/common";

.formActionSave {
  @include text-body-web-semibold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 34px;
  color: white;
  background: $purple5Color;
  border-radius: 17px;
  border: none;

  &[disabled] {
    background: mix($purple5Color, white, 50%);
    border: 2px solid mix($purple5Color, white, 50%);
    cursor: not-allowed;
  }
}
