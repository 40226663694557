@import 'uni/style/common';

@mixin multine-ellipsis($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.outer {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  @include button-reset;
  padding: 8px 16px;
  border: 1px solid $gray2Color;
  margin: 16px 24px 0 24px;

  min-height: 52px;
  border-radius: 10px;
  cursor: default;
}

.icon {
  margin-right: 16px;
  color: $gray6Color;
}

.text {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.title {
  @include text-callout-web-medium;
  color: $gray6Color;

  @include multine-ellipsis(2);
}

.caption {
  margin-top: 2px;

  @include text-callout-web-regular;
  color: $gray6Color;

  @include multine-ellipsis(2);
}

.addon {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  top: 8px;
  right: 10px;

  @include text-caption-web-semibold;
  color: $gray6Color;
  background-color: white;
}

.addonIcon {
  color: $gray6Color;
  margin-right: 5px;
}

.outer.__clickable {
  cursor: pointer;

  .title {
    color: $blackColor;
  }
}
