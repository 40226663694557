@import 'uni/style/common';

.outer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.instructions {
  @include text-callout-web-regular;
  background-color: $yellow1Color;
  padding: grid(2);
  border: 1px solid $yellow4Color;
  border-radius: 12px;

  .doubleName {
    color: $yellow6Color;
  }

  .instructionContent {
    a,
    p,
    em,
    li,
    strong {
      color: $yellow6Color;
    }
  }
}
.title {
  @include text-body-web-regular;
  color: $gray6Color;
}

.fields {
  padding: 0;
  margin: 0;
  list-style: none;
  pointer-events: none;
  opacity: 0.5;
  margin-bottom: grid(5);
}

.question {
  @include text-title-web-bold;
  color: $blackColor;
  margin: grid(3) 0 grid(2) 0;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 grid(3);
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  box-shadow: 0px -1px 0px $gray2Color;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.editButtonOuter {
  flex: 1;
  display: flex;
}

.editButton {
  @include button-default-secondary;
}

.doneButton {
  @include button-default-primary;
}
