// 3 types of buttons:
//  - default
//  - action
//  - timeline

// LEGACY
@mixin button-default-base {
  padding: 6.5px 16px;
  border-radius: 18px;
  border: none;
  height: 34px;
  cursor: pointer;
}

@mixin button-default-primary {
  @include button-default-base;
  @include text-body-web-semibold;
  color: white;
  background-color: $purple5Color;

  &:hover {
    background-color: $purple6Color;
  }

  &:disabled {
    cursor: not-allowed;
    background: mix($purple5Color, white, 50%);
  }
}

@mixin button-default-secondary {
  @include button-default-base;
  @include text-body-web-semibold;
  color: $blackColor;
  background-color: $gray2Color;

  &:hover {
    background-color: $gray3Color;
  }

  &:disabled {
    cursor: not-allowed;
    background: mix($gray2Color, white, 50%);
  }
}

// CURRENT

@mixin button-base {
  @include text-callout-web-medium;
  display: flex;
  height: 28px;
  border-radius: grid(1);
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@mixin button-primary {
  @include button-base;
  color: $whiteColor;
  background-color: $purple5Color;
  border: none;

  &:disabled {
    cursor: not-allowed;
    background: mix($purple5Color, white, 50%);
  }
}

@mixin button-secondary {
  @include button-base;
  color: $blackColor;
  background-color: $whiteColor;
  border: 1px solid $gray3Color;

  &:disabled {
    cursor: not-allowed;
    background: mix($gray3Color, white, 50%);
  }
}

@mixin big-button-base {
  @include text-body-web-medium;
  border: none;
  border-radius: 10px;
  padding: 7.5px 12px;
  cursor: pointer;
  height: 36px;
  align-items: center;
  justify-content: center;
  min-width: 75px;

  &:disabled {
    cursor: not-allowed;
    background: $gray1Color;
    color: $gray6Color;
    border: 1px solid $gray1Color;
  }
}

@mixin big-button-primary {
  @include big-button-base;
  color: $whiteColor;
  background-color: $purple5Color;
}

@mixin big-button-secondary {
  @include big-button-base;
  color: $blackColor;
  background-color: $whiteColor;
  border: 1px solid $gray3Color;
}
