@import 'uni/style/common';

.accountEmail {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 23px;
  }

  &Icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: grid(2);
    width: 45px;
    height: 45px;
    background: #ffffff;

    border: 0.5px solid #e5e5ea;
    border-radius: 60px;

    img {
      width: 25px;
      height: 25px;
    }
  }

  &IconWarning {
    position: absolute;
    color: $flamingo6Color;
    bottom: -3px;
    right: -6px;
  }

  &Info {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &Label {
    @include text-body-web-regular;
    color: $blackColor;
    margin-bottom: 5px;
  }

  &Primary {
    @include text-body-web-regular;
    color: $gray6Color;
  }

  &Status {
    @include text-body-web-regular;
    color: $gray6Color;

    &.__danger {
      @include text-body-web-semibold;
      color: $flamingo6Color;
    }
  }

  &Action {
    &Reconnect {
      @include text-body-web-semibold;
      cursor: pointer;
      color: $blackColor;
      padding: 6.5px 16px;
      background: $gray2Color;
      border-radius: 17px;
    }
  }
}
