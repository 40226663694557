@import 'uni/style/common';

.label {
  @include text-title-web-bold;

  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
}
