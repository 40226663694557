@import "uni/style/common";

// stylelint-disable property-no-unknown
:export {
  flamingo6Color: $flamingo6Color;
}
// stylelint-enable property-no-unknown

.section {
  margin-top: grid(6);
  display: flex;

  &Column {
    flex-flow: column;
  }
}


.sectionTitle {
  @include text-title-web-medium;
  font-size: 17px;
  color: #161618;
  margin-bottom: 24px;
}

.invoice {
  &Outer {
    display: flex;
    flex-flow: row;
    padding: grid(2);
    border: 1px solid #ECEDF0;
    border-radius: 8px;
    margin-bottom: grid(1.5);
    max-width: 680px;
    user-select: none;
  }

  &Left {
    display: flex;
    flex: 1;
    align-items: center;
  }

  &LeftIcon {
    color: #C1C4CD;
    border: 1px solid #ECEDF0;
    border-radius: 5px;
    margin-right: grid(1.5);
    padding: grid(1);
  }

  &CreatedAt {
    margin-bottom: grid(0.5);
    @include text-callout-web-regular;
    color: #9095A2;
  }

  &Amount {
    @include text-callout-web-regular;
    color: #161618;
  }

  &Right {
    display: flex;
    align-items: center;
  }

  &Download {
    color: #9095A2;
    cursor: pointer;
  }
}

.invoiceNotice {
  @include text-body-web-regular;
  color: $gray6Color;
  margin-bottom: 8px;

  a {
    text-decoration: none;
    color: $purple5Color;
  }
}