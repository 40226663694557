@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: column;

  padding: 0 32px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.inner {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}

.content {
  flex: 1;
}
