@import "uni/style/common";

.outer {
  flex: 1;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 150px 40px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.infoSection {
  .avatar {
    height: 112px;
    width: 112px;
    border-radius: 70px;
    margin-bottom: grid(2);
  }

  .title {
    @include text-headline-web-bold;
    margin-bottom: 4px;
  }
}



.btnOuter {
  @include button-reset;
  max-width: 900px;
  min-width: 360px;
  margin: 16px 0 24px;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  @include text-body-web-semibold;

  color: $gray6Color;
  margin-left: 8px;
}

.icon {
  color: $gray6Color;
}

.btnOuter:hover .label {
  color: $purple5Color;
}

.btnOuter:hover .icon {
  color: $purple5Color;
}
