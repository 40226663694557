@import 'uni/style/common';

$buttonIconHeight: grid(4);

.outer {
  display: flex;
  @include flex-center;
  @include rounded($buttonIconHeight);
  height: $buttonIconHeight;
  min-width: $buttonIconHeight;
  padding: 0px 4px !important;
  transition: background-color 200ms ease;
  flex-direction: row;

  color: $gray5Color;

  &:hover {
    background-color: $gray1Color;
    color: $gray6Color;
  }
}

.focused {
  background-color: $gray1Color;
  color: $gray6Color;
}

.active {
  color: $darkPurpleColor;

  &:hover {
    background-color: $purple1Color;
    color: $darkPurpleColor;
  }
}

.active.focused {
  background-color: rgba($purpleColor, 0.14);
  &:hover {
    background-color: rgba($purpleColor, 0.2);
  }
}
