@import "uni/style/common";

.sectionOuter {
  border-top: 1px solid $gray2Color;
  padding-top: 32px;
  margin-top: 32px;
}

.sectionTop {
  display: flex;
  margin-bottom: grid(1);
}

.sectionTitle {
  @include text-title-web-medium;
  flex: 1;
  color: $blackColor;
  user-select: none;
}

.sectionAction {
  @include text-title-web-medium;
  color: $purple5Color;
  cursor: pointer;
  user-select: none;
}

.sectionOuter.__disabled .sectionAction {
  color: $gray4Color;
  cursor: default;
}
