@import 'uni/style/common';

.outer {
  display: flex;
  align-items: flex-start;
  flex-flow: column;

  user-select: none;

  margin: 20px 0;
  padding: 20px;

  border-radius: 8px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid $gray2Color;
}

.outerWithoutIcons {
  padding-top: 8px;
}

.icon {
  margin-bottom: 4px;
}

.title {
  @include text-title-web-bold;
  margin: 12px 0 0 0;
}

.steps {
  margin: 12px 0;

  .link {
    color: $purple5Color;
    text-decoration: none;
    cursor: pointer;
  }
}

.step {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 0;

  .stepNumber {
    @include text-label-web-bold;
    color: $gray6Color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 9px 4px 9px;
    border-radius: 24px;
    border: 1px solid $gray2Color;
    margin-right: grid(2);
    max-height: grid(3);
    max-width: grid(3);
  }

  .stepMessage {
    @include text-body-web-regular;
    color: $gray6Color;
  }
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include text-body-web-regular;
  color: $gray6Color;

  a {
    text-decoration: underline;
    color: $gray6Color;
    cursor: pointer;
  }

  p {
    margin: 16px 0 0 0;
    padding: 0;
  }

  ul {
    margin: 16px 0 0 0;
    padding: 0 0 0 23px;
  }

  li {
    padding: 0;
    margin: 0;
  }
}
