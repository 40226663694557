@import 'uni/style/common';

.outer {
  position: absolute;
  bottom: 40px;

  &.right {
    right: 40px;
  }

  button {
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: none;
    @include text-title-web-medium;
    padding: 10px;
    background: $blackColor;
    color: $whiteColor;
  }
}
