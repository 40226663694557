@import 'uni/style/common';

.notifyLabel {
  @include text-callout-web-medium;
  color: $gray6Color;
}

.notifyOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.notifyButton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.notifyInfo {
  display: block;
  color: $gray6Color;
  width: 24px;
  height: 24px;
  margin-left: 2px;
  margin-right: 6px;
}

.notifyInfoTooltip {
  max-width: 252px;
}

.fileOuter {
  margin-right: 14px;
}

.emojiOuter {
  position: relative;
  margin-right: 14px;
}

.emojiButton {
  cursor: pointer;
  color: $gray6Color;
}

.emojiPicker {
  position: absolute;
  bottom: 110%;
  right: 0;
}
