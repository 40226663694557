@import 'uni/style/common';

.icon {
  color: $gray6Color;
}

.menu {
  display: none;
}

.showOnHoverOnly {
  visibility: hidden;
}

.outer {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  cursor: pointer;
  transition: background 0.2s ease;
  border-radius: grid(1);
  user-select: none;

  background: transparent;

  .rightAccessory {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &.__urgent {
    background: $flamingo1Color;
    .rightAccessory {
      * {
        border-color: $flamingo1Color;
      }
    }
  }

  &.__needsAttention {
    background: $yellow1Color;
    .rightAccessory {
      * {
        border-color: $yellow1Color;
      }
    }
  }

  &.__selected {
    background: $purple1Color;
    .rightAccessory {
      * {
        border-color: $purple1Color;
      }
    }
  }

  &.__dragged {
    @include shadowed($radius: 6px, $alpha: 0.1);
    background: white;
  }

  .dot {
    display: none;
  }
  &.__unread .dot {
    display: unset;
  }

  &:hover:not(.__noHoverBackground) {
    background: $gray1Color;
    .rightAccessory {
      * {
        border-color: $gray1Color;
      }
    }

    .dot {
      display: unset;
    }

    .menu {
      display: inline-block;
    }

    .showOnHoverOnly {
      visibility: visible;
    }

    .reminderLabel {
      display: none;
    }
  }
}

.inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.left {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding: 0 8px;
}

.navigate {
  color: $gray5Color;
}

.private {
  color: $gray6Color;
}

.attentionIcon {
  color: $yellow5Color;
}
