@import "uni/style/common";

.outer {
  display: flex;
  flex-direction: row;
  margin: 0 grid(3) 12px grid(2.5);
  padding-left: grid(1);
}

.icon {
  &Outer {
    margin-right: 12px;
    align-self: center;
  }

  &Glyph {
    color: white;
  }
}

.content {
  display: flex;
  flex-direction: column;

  .title {
    @include text-callout-web-medium;
    color: $blackColor;
  }

  .description {
    @include text-callout-web-regular;
    color: $gray6Color;
  }
  .incomplete {
    color: $flamingo6Color;
  }

}

.modalBtn {
  @include text-body-web-semibold;
  position: absolute;
  background-color: $gray2Color;
  border-radius: 20px;
  color: $blackColor;
  padding: 6.5px 16px;
  right: grid(3);
  cursor: pointer;
}
