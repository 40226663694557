@import "uni/style/common";

.outer {
  justify-content: center;
}

.rightDot {
  @include circle(8px);
  background-color: $purpleColor;
}

.big {
  @include circle(10px);
}
