@import 'uni/style/common';

.outer {
  @include text-body-web-regular;
  border-top: 1px solid #2a2a2c;
  border-bottom: 1px solid #2a2a2c;
  padding: grid(1.75) grid(2);
  height: grid(8);
  vertical-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .timePickerLabel {
    color: white;
  }

  .timePickerBtn {
    cursor: pointer;
    color: $whiteColor;
    background-color: #363638;
    height: 36px;
    width: 132px;
    border-radius: 10px;
    padding: 7px 10px;
  }
}
