@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  gray6Color: $gray6Color;
  gray5Color: $gray5Color;
  orange1Color: $orange1Color;
  orange6Color: $orange6Color;
  yellow1Color: $yellow1Color;
  yellow6Color: $yellow6Color;
  yellow5Color: $yellow5Color;
  flamingo1Color: $flamingo1Color;
}

.values {
  gray6Color: $gray6Color;
  gray5Color: $gray5Color;
  orange1Color: $orange1Color;
  orange6Color: $orange6Color;
  yellow1Color: $yellow1Color;
  yellow6Color: $yellow6Color;
  yellow5Color: $yellow5Color;
}
// stylelint-enable property-no-unknown
