@import 'uni/style/common';



.outer {
  @include text-body-web-regular;
  border: none;
  outline: none;
  padding: 0 3px;
  color: $blackColor;
  width: 100%;
  resize: none;

  &::placeholder {
    color: $gray5Color;
  }
}
