@import "uni/style/common";


.sectionSubtitle {
  @include text-body-web-regular;
  flex: 1;
  color: $gray6Color;
  margin-bottom: grid(3);
}

.sectionPlaceholder {
  @include text-body-web-regular;
  color: $gray6Color;
}
