@import 'uni/style/common';

.outer {
  margin-bottom: grid(6);
}

.checkIcon {
  color: $gray4Color;
}

.checkedIcon {
  color: $purple5Color;
}

.item {
  display: flex;
  align-items: center;
}

.item + .item {
  margin-top: grid(2);
}

.checkbox {
  margin-right: grid(2);
}

.label {
  @include text-title-web-regular;
  color: $blackColor;
  cursor: pointer;
}

.error {
  display: block;
  @include text-callout-web-semibold;
  margin-top: grid(1);
  color: $flamingo6Color;
}
