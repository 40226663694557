@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: 64px;
  margin-bottom: 32px;
}

.icon {
  margin-right: 18px;
}

.texts {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px 0;
}

.title {
  @include text-body-web-medium;
}

.description {
  @include text-body-web-regular;
  color: $gray6Color;
  margin-top: 4px;
}

.primaryButton {
  @include button-primary;
  margin-left: 8px;
}

.secondaryButton {
  @include button-secondary;
}
