@import 'uni/style/common';

.outer.outer {
  cursor: pointer;
  position: relative;
  padding: 10px;
  display: block;

  &::after {
    display: block;
    @include circle(8.33px);
    content: '';
    background-color: $gray4Color;
  }

  &:hover::after {
    background-color: $gray5Color;
  }

  &.__unread::after {
    background-color: $purpleColor;
  }

  &.__silent::after {
    background-color: $gray4Color;
  }
}
