@import "uni/style/common";

.outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.templateIntroOuter {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  margin: 16px;
}

.topicTitle {
  margin: 24px;
  color: black;
  @include text-title-web-bold;

}

.contentOuter {
  margin: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  img {
    width: 46px;
    height: 48px;
  }
}

.contentTitle {
  @include text-title-web-bold;
  color: $gray6Color;
  margin: 24px 0px;
}

.description {
  p {
    @include text-body-web-regular;
    color: $gray6Color;
    margin: 0px;

    &:last-child {
      margin-top: 20px;
    }
  }
}

