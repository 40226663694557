@import 'uni/style/common';

.outer {
  padding: 20px;
  margin: auto;
  max-width: 680px;
  position: relative;
}

.header {
  @include text-headline-web-bold;
  color: #161618;
}

.section {
  margin-top: 40px;
  display: flex;

  &Column {
    flex-flow: column;
  }

  &.__no_separator {
    border-top: none !important;
    padding-top: 0 !important;
  }
}

.section + .section {
  border-top: 1px solid $gray2Color;
  padding-top: 40px;
}

.sectionTitle {
  @include text-title-web-medium;
  color: $blackColor;
  margin-bottom: 24px;
  user-select: none;
}

.button {
  @include text-body-web-semibold;
  background: $gray2Color;
  border: 2px solid $gray2Color;
  border-radius: 24px;
  padding: 6px 13px;
  cursor: pointer;
  margin-right: 14px;

  &.__disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.__primary {
    background: $purpleColor;
    border: 2px solid $purpleColor;
    color: white;

    &:hover {
      background: #581bf5;
      border: 2px solid #581bf5;
    }
  }

  &:hover {
    background: #e1e2e7;
    border: 2px solid #e1e2e7;
  }
}

.navigation {
  margin-bottom: grid(6);

  &Icon {
    cursor: pointer;
    color: $gray6Color;
  }
}

.label {
  @include text-callout-web-regular;
  color: $gray6Color;
  margin-bottom: 10px;
}

.input {
  @include text-body-web-regular;
  background: #ecedf0;
  border-radius: 10px;
  padding: 8px 10px 7px 10px;
  border: 2px solid #ecedf0;
  margin-bottom: grid(2);

  &Select {
    appearance: none;
    width: 100%;

    &Outer {
      position: relative;
    }

    &Icon {
      color: black;
      position: absolute;
      right: 8px;
      top: 8px;
      pointer-events: none;
    }
  }

  &Card {
    height: 40px;
    padding-top: 9px;
  }

  &::placeholder {
    color: #9095a2;
  }

  &:focus {
    border: 2px solid #9082f7;
    background: white;
  }

  &.__error {
    border: 2px solid $flamingo6Color;
  }
}

.noticeError {
  position: absolute;
  display: flex;
  align-items: center;
  padding: grid(1);
  right: 80px;
  left: 80px;
  top: 10px;

  /* Secondary / Flamingo1 */
  background: #fcefee;
  border-radius: 8px;

  &Content {
    @include text-body-web-medium;
    font-size: 14px;
    color: #ff6e66;
  }

  &Icon {
    color: #ff6e66;
    margin-right: grid(1);
  }
}
