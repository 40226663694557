@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 20px 0 20px 0;

  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: unset;
  }
}

.wrapper {
  flex: 1 0 auto;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  @media (min-width: 1120px) {
    max-width: 352px;
  }
}

.wrapper:nth-child(odd) {
  margin-right: 16px;
  @media (max-width: 1120px) {
    margin-right: 0;
  }
}
