@import "uni/style/common";

.outer {
  position: relative;
  user-select: none;
}

.handler {
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 20px;
  width: 100px;
  display: flex;
  align-items: center;

  &Label {
    @include text-callout-web-medium;
    color: $gray6Color;
  }

  &Icon {
    @include text-callout-web-medium;
    color: $gray6Color;
    margin-right: 2px;
  }

  &.__active, &:hover {
    background-color: $gray1Color;
  }
}

.menu {
  position: absolute;
  z-index: 1;
  background-color: #FFF;

  padding: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px $gray2Color;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  right: 0;
  width: 192px;


  &ItemIcon {
    color: $blackColor;
    display: none;
  }

  &ItemLabel {
    @include text-body-web-regular;
    color: $blackColor;
    padding-left: 30px;
  }

  &Item {
    border-radius: 6px;
    padding: 5px 0;
    display: flex;
    align-items: center;

    &:hover {
      background: $gray2Color;
    }

    &.__selected {
      .menuItemIcon {
        display: block;
        margin-right: 2px;
        margin-left: 4px;
      }

      .menuItemLabel {
        padding-left: 0;
      }
    }
  }

  &.__hidden {
    display: none;
  }
}
