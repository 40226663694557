@import 'uni/style/common';

$avatarSize: grid(3);
$gutterWidth: $avatarSize + grid();

// stylelint-disable property-no-unknown
:export {
  avatarSize: $avatarSize;
}
// stylelint-enable property-no-unknown

.avatar {
  align-self: flex-end;
}

.emojis {
  padding: 0 grid(0.5);
  max-width: 70%;
  margin: 0 #{$gutterWidth - $avatarSize};
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
}

.emojisText {
  font-size: 2rem;
}
