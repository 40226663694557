@import 'uni/style/common';

.snoozeActions {
  position: relative;
  padding: grid(2);
  height: 66px;

  .snoozeActionBtns {
    display: inline-block;
    position: absolute;
    right: 16px;
  }

  .modalBtn {
    height: 34px;
    @include text-callout-web-medium;
    border: none;
    padding: 7px 12px;
    cursor: pointer;
    border-radius: 40px;

    .loader {
      display: none;
      color: white;
      font-size: 12px !important;
      height: 18px !important;
      width: 16px !important;
      margin-right: 8px;
      animation: spinner 0.7s infinite linear;
    }

    &.__loading {
      cursor: progress;

      .loader {
        display: inline-block;
      }
    }
  }

  .modalDeleteBtn {
    display: inline-block;
    background-color: #363638;
    color: $flamingo6Color;
    position: relative;
    left: 0;
    &:hover {
      background-color: rgba(#363638, 0.6);
    }
  }
  .modalCancelBtn {
    background-color: #363638;
    color: $gray6Color;
    &:hover {
      background-color: rgba(#363638, 0.6);
    }
  }
  .modalSaveBtn {
    color: white;
    background-color: $purple5Color;
    margin-left: 10px;
    &:hover {
      background-color: $purple6Color;
    }
  }
}
