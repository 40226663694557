@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  tintOn: $purple5Color;
  tintOff: $gray4Color;
}
// stylelint-enable property-no-unknown

.outer {
  display: flex;
}

.input {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  padding: 0;
  margin: 0;
  opacity: 0;

  cursor: pointer;
}

.decoration {
  pointer-events: none;
}

.input:disabled + .decoration {
  opacity: 0.5;
}
