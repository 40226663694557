@import 'uni/style/common';

.outer {
  @include absolute;
  display: flex;
  flex-direction: column;

  box-sizing: content-box;
  max-width: 1104px;
  padding: 0 grid(3);
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  align-content: center;
}

.input {
  @include input-reset;

  height: 36px;
  border: 1px solid $gray2Color;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px;
  width: 280px;

  @include text-body-web-regular;
  color: $blackColor;
}

.input::placeholder {
  color: $gray5Color;
}

.input.__focus,
.input:focus {
  box-shadow: 0px 0px 0px 2px $purple2Color;
  border: 1px solid $purple4Color;
}

.error {
  @include text-callout-web-regular;
  color: $flamingo6Color;
  margin-top: 8px;
}

.inputContainer {
  display: flex;
  @include flex-center;
  margin-top: 56px;
}

.buttons {
  display: flex;
  @include flex-center;
  margin-top: grid(3);
}

.info {
  @include text-title-web-regular;
}
