/**
 * New color palette
 * https://www.figma.com/file/CIYSAMSkgFs9DgAabdEyk6Ua/UI-Kit?node-id=11712%3A1979
 */

$blackColor: #161618;
$whiteColor: #ffffff;
$darkGrayColor: #606163;

$gray1Color: #f7f8f9;
$gray2Color: #ecedf0;
$gray3Color: #e1e2e7;
$gray4Color: #d4d6dd;
$gray5Color: #c1c4cd;
$gray6Color: #818691;
$gray7Color: #4c4e55;

$purple1Color: #ece9fd;
$purple2Color: #d0c9fb;
$purple3Color: #b0a6f9;
$purple4Color: #9082f7;
$purple5Color: #644bff;
$purple6Color: #581bf5;

$flamingo1Color: #fcefee;
$flamingo2Color: #f8d6d2;
$flamingo3Color: #f4bab5;
$flamingo4Color: #f19f97;
$flamingo5Color: #ff6e66;
$flamingo6Color: #eb5144;

$orange1Color: #faeee8;
$orange2Color: #f2d3c5;
$orange3Color: #eab69f;
$orange4Color: #e29879;
$orange5Color: #e66636;
$orange6Color: #c54628;

$yellow1Color: #fdf6eb;
$yellow2Color: #f9e9cb;
$yellow3Color: #f5d9a9;
$yellow4Color: #f0ca87;
$yellow5Color: #f4b144;
$yellow6Color: #df8d3c;

$green1Color: #edf7ee;
$green2Color: #d2ead5;
$green3Color: #b5ddb9;
$green4Color: #98ce9e;
$green5Color: #54bc6d;
$green6Color: #4d9549;

$blue1Color: #eaf4fd;
$blue2Color: #bfdef8;
$blue3Color: #99cbf4;
$blue4Color: #75b8f0;
$blue5Color: #2a92e8;
$blue6Color: #146eb8;

$violet1Color: #f5e9fa;
$violet2Color: #debaef;
$violet3Color: #cd97e6;
$violet4Color: #bd75de;
$violet5Color: #9727cc;
$violet6Color: #712097;

$rose1Color: #fcf0f7;
$rose2Color: #f4cee7;
$rose3Color: #eeb5db;
$rose4Color: #e99ccf;
$rose5Color: #dc71ba;
$rose6Color: #b53c8e;

/**
 * Colors for Discover Categories
 */
$electricColor: #835ef5;
$lavenderColor: #a375f1;
$roseColor: #f090a6;
$waterColor: #58a5f8;
$cornColor: #f6bc6c;
$lakeColor: #6bc8d0;
$coalColor: #3d3d3d;

/**
 * LEGACY COLORS DON'T USE THEM !
 */

// Use this along with BlurView in dark mode to get almost a blurred rgba(46, 50, 71, 0.8)
$blackColorForBlurring: rgba(190, 205, 240, 0.8);

$grayColor: $gray5Color;
$lightGrayColor: $gray1Color;
$mediumGrayColor: $gray3Color;

$grayBorderColor: $gray2Color;

$purpleColor: $purple5Color;
$darkPurpleColor: $purple6Color;
$lightPurpleColor: $purple1Color;
$extraLightPurpleColor: $gray1Color;

$yellowColor: $yellow5Color;
$darkishYellowColor: $yellow6Color;
$darkYellowColor: $yellow6Color;
$lightYellowColor: $yellow1Color;

$flamingoColor: $flamingo5Color;
$darkFlamingoColor: $flamingo6Color;
$lightFlamingoColor: $flamingo1Color;

$greenColor: $green5Color;
$darkGreenColor: $green6Color;
$lightGreenColor: $green1Color;

$orangeColor: $orange5Color;
$darkOrangeColor: $orange6Color;
$lightOrangeColor: $orange1Color;

/* stylelint-disable property-no-unknown */
:export {
  purple5Color: $purple5Color;
}
/* stylelint-enable property-no-unknown */
