@import "uni/style/common";

.text,
.icon {
  @include text-title-web-medium;
  color: $gray6Color;
}

.icon {
  margin-bottom: 16px;
}
