@import 'uni/style/common';

.modalBtn {
  height: 34px;
  @include text-callout-web-medium;
  border: none;
  padding: 7px 12px;
  cursor: pointer;
  border-radius: 40px;

  .loader {
    display: none;
    color: white;
    font-size: 12px !important;
    height: 18px !important;
    width: 16px !important;
    margin-right: 8px;
    animation: spinner 0.7s infinite linear;
  }

  &.__loading {
    cursor: progress;

    .loader {
      display: inline-block;
    }
  }
}
