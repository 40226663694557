@import 'uni/style/common';

$knobSize: 14px;
$buttonSize: grid(5);
$trackTransitionTime: 300ms;
$defaultTint: $purpleColor;

// Styles

.inner {
  flex: 1;
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  margin: 0 grid(0.5);
  min-height: $buttonSize;

  &:before {
    // Trick to get a correct baseline:
    // have some hidden text as the first element, before the play icon
    content: '\00A0';
    @include text-callout-web-medium;
    width: 0;
  }
}

.loaderOuter {
  padding: 0 grid(0.75);
}

.button {
  display: flex;
  cursor: pointer;
  user-select: none;
  @include square($buttonSize);
  @include flex-center;
  margin-left: -#{grid()};
}

.icon {
  color: $defaultTint;
}

.progressTrackOuter {
  height: $knobSize;
  margin: 0 grid(1);
  display: flex;
  cursor: pointer;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.progressTrack {
  display: flex;
  flex-direction: column;
  height: 4px;
  flex: 1;
  border-radius: 4px;
  background: $mediumGrayColor;
  overflow: hidden;
  pointer-events: none;
}

.progressTrackAdvance {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  background: $purpleColor;
  height: 100%;
  transition: width $trackTransitionTime linear;
  pointer-events: none;
}

.durationText {
  @include text-callout-web-medium;
  color: $gray6Color;
  min-width: grid(4);
  text-align: right;
  margin-left: grid();
}

.transcriptText {
  @include text-callout-web-medium;
  margin: grid() 0;
}

.knob {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
}
