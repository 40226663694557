.outer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
}

.outer svg {
  margin-right: 6px;
}
