@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  gray6Color: $gray6Color;
}

.modal {
  margin: grid(0.5);
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0 2px 16px rgba($blackColor, 0.14),
    0 2px 4px rgba($blackColor, 0.08);
  background: rgba($blackColor, 0.96);
}
