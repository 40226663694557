@import 'uni/style/common';

.outer {
  @include text-body-web-regular;

  padding: 16px 20px;
  background: $gray1Color;
  color: $gray6Color;
  border-radius: 8px;
  margin-top: 24px;
}

.outer a {
  color: $purple5Color;
  text-decoration: none;
}

.outer strong {
  @include text-body-web-medium;

  color: $gray6Color;
}
