@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  // bar colors
  purple4Color: $purple4Color;
  blue4Color: $blue4Color;
  rose4Color: $rose4Color;
  violet4Color: $violet4Color;
  purple3Color: $purple3Color;
  blue3Color: $blue3Color;
  rose3Color: $rose3Color;
  violet3Color: $violet3Color;
  purple2Color: $purple2Color;
  blue2Color: $blue2Color;
  rose2Color: $rose2Color;
  violet2Color: $violet2Color;
  // legend color
  gray6Color: $gray6Color;
  // lines
  gray2Color: $gray2Color;
}
// stylelint-enable property-no-unknown

.weeklyChart {
  padding-bottom: 24px;

  :global(.recharts-default-legend) {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    align-items: center;
    margin: 24px 0 0 88px !important;
  }

  :global(.recharts-legend-item) {
    display: flex !important;
    margin-right: 16px !important;
    align-items: center;
    justify-content: center;
  }

  :global(.recharts-legend-item-text) {
    margin-left: 4px !important;
  }

  :global(.recharts-cartesian-axis-tick) {
    text {
      color: $gray6Color !important;
      font-size: 11px;
    }
  }

  :global(.recharts-label) {
    tspan {
      color: $gray6Color !important;
      font-size: 11px;
    }
  }
}

.inner {
  padding: 18px 0 0;
}

.weeklyChartTooltip {
  &Outer {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #ecedf0;
    border-radius: 10px;
    padding: 12px;
    display: flex;
    flex-direction: column;
  }

  &Title {
    @include text-caption-web-regular;
    color: $blackColor;
    margin-bottom: 8px;
  }

  &Item {
    @include text-caption-web-regular;
  }

  &Item + &Item {
    margin-top: 8px;
  }
}

.loader {
  margin: 24px;
}
