@import 'uni/style/common';

.inner {
  padding: 7px 0px 24px;
}

.tablesOuter {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 16px;

  table + table {
    margin-right: 24px;
  }
}

.info {
  @include text-label-web-regular;
  color: $gray6Color;
  padding: 0 24px;
}

.loader {
  margin: 24px;
}

.statusCritical {
  color: $flamingo6Color;

  .value & {
    font-weight: bold;
    &:before {
      content: '🚨 ';
    }
  }
}

.statusWarning {
  color: $yellow6Color;

  .value & {
    font-weight: bold;
  }
}

.statusGreat {
  color: $green5Color;

  .value & {
    font-weight: bold;
    &:before {
      content: '🎉 ';
    }
  }
}
