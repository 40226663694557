@import 'uni/style/common';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.viewAll {
  padding-right: 6px;

  @include text-body-web-medium;
  color: $purple5Color;
}

// stylelint-disable property-no-unknown
:export {
  arrowColor: $purple5Color;
}
// stylelint-enable property-no-unknown
