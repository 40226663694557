@import 'uni/style/common';

.input {
  @include text-body-web-regular;
  width: 100%;
  background: white;
  border: 1px solid $gray2Color;
  border-radius: 10px;
  padding: 8px 12px 7px;
  color: $blackColor;

  &:focus {
    border: 1px solid #9082f7;
  }

  &::placeholder {
    color: $gray6Color;
  }

  &.__error {
    border: 2px solid $flamingo6Color;
  }
}

.select {
  appearance: none;
  width: 100%;
  color: $blackColor;

  &.__placeholder {
    color: $gray6Color;
  }

  &.__error {
    border: 2px solid $flamingo6Color;
  }
}

.selectOuter {
  position: relative;
}
