$iPhoneX-height: 812px;
$iPhoneX-width: 375px;

$iPhoneXS-height: $iPhoneX-height;
$iPhoneXS-width: $iPhoneX-width;

$iPhoneXSMax-height: 896px;
$iPhoneXSMax-width: 414px;

$iPhoneXR-height: 896px;
$iPhoneXR-width: 414px;

$iPhone8-height: 667px;
$iPhone8-width: 375px;

$screen-s-width: 576px;
$screen-m-width: 768px;
$screen-lg-width: 992px;
$screen-xl-width: 1200px;

@mixin iPhoneX-portrait {
  @media (height: #{$iPhoneX-height}) {
    @content;
  }
}

@mixin ge-iPhoneX-portrait {
  @media (min-height: #{$iPhoneX-height}) {
    @content;
  }
}

@mixin gt-iPhoneX-portrait {
  @media (min-height: #{$iPhoneX-height + 1px}) {
    @content;
  }
}

@mixin lt-iPhoneX-portrait {
  @media (max-height: #{$iPhoneX-height - 1px}) {
    @content;
  }
}

@mixin iPhoneXSMax-portrait {
  @media (height: #{$iPhoneXSMax-height}) {
    @content;
  }
}

@mixin gt-iPhone8-portrait {
  @media (min-height: #{$iPhone8-height + 1px}) {
    @content;
  }
}

@mixin lt-iPhone8-portrait {
  @media (max-height: #{$iPhone8-height - 1px}) {
    @content;
  }
}

/* Small devices (landscape phones, 576px and up) */
@mixin screen-s {
  @media (max-width: #{$screen-m-width - 1px}) {  
    @content;
  }
}

/* Medium devices (tablets, 768px and up) */
@mixin screen-m {
  @media (min-width: #{$screen-m-width}) and (max-width: #{$screen-lg-width - 1px}) {
    @content;
  }
}

/* Large devices (desktops, 992px and up) */
@mixin screen-lg {
  @media (min-width: #{$screen-lg-width})  and (max-width: #{$screen-xl-width - 1px}) { 
    @content;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@mixin screen-xl {
  @media (min-width: #{$screen-xl-width}) {  
    @content;
  }
}
