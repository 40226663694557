@import 'uni/style/common';

.header {
  display: flex;
  flex-direction: row;
  height: grid(9);
  align-items: center;

  @media screen and (max-width: 799px) {
    justify-content: center;
  }
}

.logoLink {
  display: inline-block;
}
