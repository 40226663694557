@import 'uni/style/common';

/**
 * Apply form box (right side)
 */

.formOuter {
  flex: 0 0 auto;
  min-width: 300px;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-self: stretch;

  .formInner {
    padding: grid(4);
    overflow: auto;

    display: flex;
    flex-direction: column;
  }

  label {
    span {
      @include text-body-web-medium;
      color: $blackColor;
    }

    input,
    textarea,
    select,
    select + div {
      margin-bottom: grid(2);
    }

    textarea {
      resize: vertical;
      min-height: 60px;
      border-color: $gray3Color;
    }

    p {
      @include text-body-web-regular;
    }
  }

  .title {
    @include text-title-web-medium;

    + label {
      margin-top: 24px;
    }
  }

  .submit {
    @include big-button-primary;
    margin-top: grid();
  }

  .selectAvailability {
    @include big-button-secondary;
  }

  .editAvailability {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: -32px;
    z-index: 1;
  }

  .footer {
    @include text-callout-web-regular;
    margin-top: 20px;
    text-align: center;
    color: $gray6Color;
  }

  .contactLink {
    color: $purple5Color;
    display: inline;
    text-align: center;

    &:hover {
      color: $purple6Color;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($gray1Color, 0.4);
  }
}
