@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 30px;

  border: 1px solid $gray2Color;
  border-radius: 10px;

  svg {
    margin: 3px 2px 3px 3px;
  }
}

.text {
  margin-right: 7px;
  margin-top: 5px;
  margin-bottom: 5px;

  @include text-callout-web-medium;
}
