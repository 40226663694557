@import 'uni/style/common';

.textInput {
  outline: none;
  border-radius: 8px;
  caret-color: $purpleColor;
  padding: grid(0.75) grid(8) grid(0.75) grid(0.5);
  border: none;

  &:not([disabled]) {
    &:focus {
      box-shadow: inset 0px 0px 0px 2px $purple4Color;
    }
  }

  @include placeholder-color($gray4Color);
}

.characterCount {
  position: absolute;
  bottom: 16px;
  right: 10px;
}

.grayed {
  color: $gray4Color;
}
