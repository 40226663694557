@import 'uni/style/common';

$activeColor: mix(#ffffff, $purpleColor, 10%);

// stylelint-disable property-no-unknown
:export {
  defaultPlaceholderColor: $gray5Color;
}
// stylelint-enable property-no-unknown

.outer {
  span {
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    color: inherit;
  }

  code {
    padding: 0 2px;
    border-radius: 2px;
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    background-color: $lightYellowColor;
  }

  ul {
    padding-left: 22px;
  }

  a {
    cursor: pointer;
    color: mix(#000, $darkPurpleColor, 10%);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  div,
  p {
    line-height: 1.5;
  }

  h1,
  h2,
  h3 {
    line-height: 1.2;
  }

  p {
    margin: 0 0 8px;
  }

  p:last-child {
    margin: 0px;
  }
}

.hoverMenu {
  display: flex;
  // Some widths are fixed cause the popover behaves weirdly when it's next to
  // the window limits. It's a bigger issue that should be addressed on its own
  min-width: 237px;

  flex-direction: row;
  align-items: center;

  margin: grid(0.5);
  padding: grid(0.5);
  border-radius: grid();

  background-color: rgba($blackColor, 0.96);
  z-index: 98765456789;
}

.hoverButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  height: grid(3);

  color: white;
  padding: 0 grid(0.5);
  border-radius: grid(0.5);
  &:hover {
    background-color: rgba(white, 0.1);
  }

  .buttonBold {
    @include text-body-web-regular;
    color: inherit;
    font-weight: 600;
  }

  .buttonItalic {
    @include text-body-web-regular;
    color: inherit;
    font-style: italic;
  }

  .linkButtonInner {
    @include text-body-web-regular;
    color: inherit;
  }

  .headingH {
    @include text-body-web-regular;
    color: inherit;
  }

  .headingLevel {
    @include text-body-web-regular;
    color: inherit;
    font-size: 10px;
    line-height: 14px;
  }
}
.hoverButton__active {
  color: $activeColor;
}

.separator {
  height: 20px;
  width: 1px;
  margin: 0 7px 0 grid();

  background-color: rgba($gray6Color, 0.4);
}

.icon {
  color: inherit;
  width: 26px !important;
}

.nodeButton {
  width: grid(4);
}

.inlineButton {
  width: 43px;
}

.heading {
  display: flex;
  flex-direction: row;
  color: inherit;
  align-items: flex-end;
}

.boldButton {
  width: 26px;
}

.italicButton {
  width: 20px;
}

.deletedButton {
  width: 26px;
  padding: 0;
}

.linkButton {
  width: 43px;
}
