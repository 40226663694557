@import 'uni/style/common';

.outer {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.nav {
  min-width: 216px;
  background: $gray1Color;
  border-right: 1px solid $gray2Color;
  display: flex;
  flex-direction: column;
  &.extraPadding {
    padding-top: 21px;
  }
}

.content {
  flex: 1;
  overflow: auto;
}

.nav .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  box-sizing: content-box;
  height: 24px;
  padding: 14px 16px 14px 16px;
}

.back {
  @include button-reset;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back .icon {
  display: block;
  padding: 0;
  margin: 0 8px 0 0;
  height: 24px;
  width: 24px;
}

.back .label {
  @include text-body-web-medium;
}

.list {
  margin: 0;
  padding: 0 10px;
  flex: 1;
  list-style-type: none;
}

.item {
  display: block;
  margin-bottom: 2px;
}

.externalLink {
  text-decoration: none;
}

.item .link {
  @include button-reset;

  display: flex;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: $gray6Color;

  .icon {
    margin: 0 8px 0 6px;
  }

  .icon,
  .label {
    @include text-body-web-medium;
    text-decoration: none;
    color: $gray6Color;
  }
}

.item.__selected .link {
  background: $gray2Color;
  color: $blackColor;

  .icon,
  .label {
    color: $blackColor;
  }
}

.item:hover .link {
  background: $gray2Color;
  color: $gray6Color;

  .icon,
  .label {
    color: $gray6Color;
  }
}

.item.__danger .link,
.item:hover.__danger .link {
  color: $flamingo6Color;

  .icon,
  .label {
    color: $flamingo6Color;
  }
}

.item:hover.__selected .link {
  color: $blackColor;

  .icon,
  .label {
    color: $blackColor;
  }
}

.version {
  @include text-caption-web-regular;
  color: $gray5Color;
  padding: 0 20px;
  margin: 18px 0 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  margin-right: 8px;
}
