@import 'uni/style/common';

// stylelint-disable property-no-unknown
:export {
  flamingo6Color: $flamingo6Color;
}
// stylelint-enable property-no-unknown

.outer {
  padding: 20px;
  margin: auto;
  max-width: 680px;
  position: relative;
}

.loaderOuter {
  margin-top: 16px;
}

.header {
  @include text-headline-web-bold;
  color: #161618;
}

.section {
  margin-top: grid(6);
  display: flex;

  &Column {
    flex-flow: column;
  }

  &ContentEmpty {
    @include text-body-web-regular;
    /* Gray / Gray6 */
    color: #9095a2;
  }

  &Item {
    &Outer {
      margin-bottom: 24px;
    }
    &Border {
      border: 1px solid #ecedf0;
      border-radius: 10px;
    }
    &Title {
      user-select: none;
      @include text-callout-web-regular;
      color: #9095a2;
    }
    &Value {
      @include text-callout-web-regular;
      color: #161618;
    }
  }

  &ActionOuter {
    display: flex;
  }
}

.sectionError {
  &Body {
    background: #f7f8f9;
    border-radius: 8px;
    padding: 12px 8px;
    display: flex;
    align-items: center;

    &Text {
      @include text-body-web-medium;
      font-size: 14px;
      /* Dark Gray */
      color: #9393a3;
    }
  }

  &Icon {
    margin-right: 8px;
    /* Dark Gray */
    color: #9393a3;
  }

  &Action {
    display: flex;
    margin-top: 20px;
  }
}

.sectionTitle {
  @include text-title-web-medium;
  font-size: 17px;
  color: #161618;
  margin-bottom: 24px;
}

.invoice {
  &Outer {
    display: flex;
    flex-flow: row;
    padding: grid(2);
    border: 1px solid #ecedf0;
    border-radius: 8px;
    margin-bottom: grid(1.5);
    max-width: 680px;
    user-select: none;
  }

  &Left {
    display: flex;
    flex: 1;
    align-items: center;
  }

  &LeftIcon {
    color: #c1c4cd;
    border: 1px solid #ecedf0;
    border-radius: 5px;
    margin-right: grid(1.5);
    padding: grid(1);
  }

  &CreatedAt {
    margin-bottom: grid(0.5);
    @include text-callout-web-regular;
    color: #9095a2;
  }

  &Amount {
    @include text-callout-web-regular;
    color: #161618;
  }

  &Right {
    display: flex;
    align-items: center;
  }

  &Download {
    color: #9095a2;
    cursor: pointer;
  }
}

.appVersion {
  @include text-caption-web-semibold;
  color: #c1c4cd;
}

.referral {
  flex-direction: row;

  &Outer {
    height: grid(7);
    width: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08),
      0px 2px 16px rgba(0, 0, 0, 0.04);
    border-radius: grid(2);
    align-items: center;
    margin-right: grid(3);
    justify-content: space-between;
    text-decoration: none;

    img {
      height: 58px;
      width: grid(9);
      margin-right: grid(3);
    }
  }

  &Content {
    display: flex;
    flex-direction: row;
  }

  &Description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &Title {
      @include text-body-web-regular;
    }

    &Subtitle {
      @include text-caption-web-regular;
      color: $gray6Color;
    }
  }

  &LearnMore {
    @include text-callout-web-medium;
    color: $purple5Color;
    margin-right: grid(3);
  }
}

.integrationItem {
  display: flex;
  align-items: center;

  &Icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: grid(2);
    width: 45px;
    height: 45px;
    background: #ffffff;

    border: 0.5px solid #e5e5ea;
    border-radius: 60px;

    img {
      width: 25px;
      height: 25px;
    }
  }

  &IconWarning {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &Info {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &Label {
    @include text-body-web-regular;
    color: $blackColor;
    margin-bottom: 5px;
  }

  &Subtext {
    @include text-body-web-regular;
    color: $gray6Color;

    a {
      text-decoration: none;
      color: $purple5Color;
    }
  }

  &Action {
    &Connect {
      @include button-default-primary;
    }

    &Connected {
      @include button-default-secondary;
      cursor: default !important;
      color: $gray6Color;
    }
  }

  &Preview {
    user-select: none;
    margin-top: 16px;
    background: $gray1Color;
    border: 1px solid $gray2Color;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
    height: 250px;

    img {
      height: 250px;
    }
  }
}

.pricingItem {
  position: relative;
  display: flex;
  align-items: center;
  margin: 16px;

  &Info {
    display: flex;
    flex: 1;
    flex-direction: column;

    &.__blurred {
      filter: blur(24px);
      user-select: none;
      pointer-events: none;
    }
  }

  &Label {
    @include text-body-web-regular;
    color: $blackColor;
    margin-bottom: 5px;
  }

  &Subtext {
    @include text-body-web-regular;
    color: $gray6Color;

    a {
      text-decoration: none;
      color: $purple5Color;
    }
  }

  &Action {
    &.__blurred {
      filter: blur(24px);
      user-select: none;
      pointer-events: none;
    }
  }

  &Button {
    @include button-default-primary;

    &Outer {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}

.pricingNotice {
  @include text-callout-web-regular;
  color: #818691;

  a {
    text-decoration: none;
    color: $purple5Color;
  }
}
