@import 'uni/style/common';

.outer {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: $gray1Color;
}

.inner {
  max-width: 752px;

  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 80px 12px;

  @include screen-s {
    padding: 40px 12px;
  }

  & > div {
    margin-bottom: 40px;

    @include screen-s {
      margin-bottom: 32px;
    }
  }

  & a {
    text-decoration: none;
    align-self: flex-end;
  }
}
