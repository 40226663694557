@import "uni/style/common";

.outer {
  padding-left: 8px;
  border-left: 2px solid $gray3Color;
  margin-left: 56px;
  margin-top: 8px;
}

.outer.__clickable:hover {
  border-left: 2px solid $gray5Color;
  background: transparent url-svg-stroke('~uni/system/icons/chevron-right-xs.svg', $gray5Color) no-repeat right center;
}

.outer:nth-child(2) {
  margin-top: 2px;
}

.outer:last-child {
  margin-bottom: 10px;
}

.author {
  @include text-callout-web-medium;
  color: $gray6Color;
}

.date,
.text {
  @include text-callout-web-regular;
  color: $gray6Color;
}

.highlight {
  @include text-callout-web-regular;
  background-color: $cornColor;
  color: $blackColor;
}
