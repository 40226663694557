@import "uni/style/common";

.outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include text-body-web-medium;
  min-height: 40px;
  padding: 8px 0;
  margin-bottom: 8px;
}
